import React from "react"
import { graphql, StaticQuery } from "gatsby"
import InnerWrapper from "./innerWrapper"

import Slide from "react-reveal/Slide"
import styles from "./scss/clients.module.scss"
const clients = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulClient {
          edges {
            node {
              logo {
                title
                description
                fixed(width: 250) {
                  src
                  srcWebp
                }
              }
              url
            }
          }
        }
      }
    `}
    render={data => (
      <section className={styles.clients}>
        <InnerWrapper width={1440}>
          <h2>Trusted by marketing teams at:</h2>
          <div className={styles.container}>
            <div className={styles.gallery}>
              {data.allContentfulClient.edges.map((edge, index) => {
                return (
                  <Slide left key={index}>
                    <a href={edge.node.url}>
                      <img
                        src={edge.node.logo.fixed.src}
                        alt={edge.node.logo.description}
                      />
                    </a>
                  </Slide>
                )
              })}
            </div>
          </div>
        </InnerWrapper>
      </section>
    )}
  />
)

export default clients
