import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import InnerWrapper from "./innerWrapper"
import styles from "./scss/blurb.module.scss"
const blurb = ({ type, title, link }) => (
  <StaticQuery
    query={graphql`
      query {
        contentfulBlurbAbout {
          blurb {
            json
          }
        }
        contentfulBlurbProcess {
          blurb {
            json
          }
        }
      }
    `}
    render={data => {
      let code

      if (type === "about") {
        code = (
          <>
            <h2>{title}</h2>
            {documentToReactComponents(data.contentfulBlurbAbout.blurb.json)}
            {/* <div className={styles.more}>
              <Link to={link}>
                Read More <span>→</span>
              </Link>
            </div> */}
          </>
        )
      } else if (type === "process") {
        code = (
          <>
            <h2>{title}</h2>
            {documentToReactComponents(data.contentfulBlurbProcess.blurb.json)}
            <div className={styles.more}>
              <Link to={link}>
                See our work <span>→</span>
              </Link>
            </div>
          </>
        )
      }

      return (
        <section className={styles.blurb}>
          <InnerWrapper width={1024}>{code}</InnerWrapper>
        </section>
      )
    }}
  />
)
export default blurb
