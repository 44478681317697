import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Carousel } from "react-responsive-carousel"
import { StaticQuery, graphql } from "gatsby"
import styles from "./scss/testimonials.module.scss"
import "./scss/carousel.css"

const testimonials = () => (
  <StaticQuery
    query={query}
    render={data => (
      <section className={styles.testimonials}>
        <h2>Testimonials</h2>
        <Carousel
          showArrows={false}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          showStatus={false}
          showIndicators={false}
          interval={15000}
        >
          {data.allContentfulTestimonial.edges.map((edge, index) => {
            return (
              <div key={index} className={styles.testimonial}>
                <blockquote>
                  <span className={styles.text}>
                    {documentToReactComponents(edge.node.review.json)}
                  </span>
                  <br />
                  <div className={styles.reviewer}>
                    <div className={styles.reviewerDetails}>
                      <cite className={styles.name}>{edge.node.name}</cite>
                      <span className={styles.companyName}>
                        {edge.node.company}
                      </span>
                    </div>
                    {edge.node.image && (
                      <img
                        className={styles.avatar}
                        src={edge.node.image.fluid.src}
                        alt={`Avatar of ${edge.node.name}`}
                      />
                    )}
                  </div>
                </blockquote>
              </div>
            )
          })}
        </Carousel>
      </section>
    )}
  />
)

// avatar

const query = graphql`
  query {
    allContentfulTestimonial {
      edges {
        node {
          review {
            json
          }
          name
          company
          image {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`

export default testimonials
