import { graphql, StaticQuery } from "gatsby"
import React from "react"
import InnerWrapper from "./innerWrapper"
import styles from "./scss/services.module.scss"
// import Reveal from "react-reveal/Reveal"
import Slide from "react-reveal/Slide"

// url

const mainServices = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulService(filter: { main: { eq: true } }, limit: 6) {
          edges {
            node {
              title
              description
              icon {
                fixed(width: 200, height: 200, quality: 100) {
                  srcWebp
                  src
                }
              }
              url
            }
          }
        }
      }
    `}
    render={data => (
      <section className={styles.services} id="services">
        <InnerWrapper width={1440}>
          <h2>Our Services</h2>
          <div className={styles.container}>
            {data.allContentfulService.edges.map((service, index) => {
              return (
                <Slide left key={index}>
                  <div className={styles.service}>
                    <picture>
                      <source
                        srcSet={service.node.icon.fixed.srcWebp}
                        type="image/webp"
                      />
                      <source
                        srcSet={service.node.icon.fixed.src}
                        type="image/png"
                      />
                      <img
                        src={service.node.icon.fixed.src}
                        alt={service.node.title + " icon"}
                      />
                    </picture>
                    <h3>{service.node.title}</h3>
                    <p>{service.node.description}</p>
                    {service.node.url && (
                      <a
                        className={styles.link}
                        href={service.node.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        See {service.node.title} example
                      </a>
                    )}
                  </div>
                </Slide>
              )
            })}
          </div>
          {/* <div className={styles.more}>
            <Link to="/services">
              See All <span>→</span>
            </Link>
          </div> */}
        </InnerWrapper>
      </section>
    )}
  />
)

export default mainServices
