import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import InnerWrapper from "./innerWrapper"
import { slugify } from "../util/utilityFunctions"
import styles from "./scss/latest-work.module.scss"
import Slide from "react-reveal/Slide"
const latestWork = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulWork(sort: { fields: createdAt, order: ASC }, limit: 6) {
          edges {
            node {
              title
              company
              link
              image {
                title
                description
                fixed(width: 600, quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <section className={styles.latestWork}>
        <InnerWrapper width={1440}>
          <h2>More about our work with</h2>
          <div className={styles.container}>
            <div className={styles.gallery}>
              {data.allContentfulWork.edges.map((edge, index) => {
                return (
                  <Slide left key={index}>
                    <Link to={`/work/${slugify(edge.node.title)}`}>
                      <div className={styles.text}>
                        {edge.node.image ? (
                          <img
                            className={styles.image}
                            src={edge.node.image.fixed.src}
                            alt={edge.node.image.title}
                          />
                        ) : (
                          ""
                        )}
                        <h4>{edge.node.title}</h4>
                        {/* <p>{edge.node.company}</p>
                        <a
                          href={edge.node.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Work
                        </a> */}
                      </div>
                    </Link>
                  </Slide>
                )
              })}
            </div>
          </div>
          {/* <div className={styles.more}>
            <Link to="/work">
              See All <span>→</span>
            </Link>
          </div> */}
        </InnerWrapper>
      </section>
    )}
  />
)

export default latestWork
