import { graphql } from "gatsby"
import React from "react"
import Blurb from "../components/blurb"
import Clients from "../components/clients"
import Contact from "../components/contact"
import GA from "../components/ga"
import Hero from "../components/hero"
import LatestWork from "../components/latest-work"
import Layout from "../components/layout"
import MainServices from "../components/main-services"
import Testimonials from "../components/testimonials"
import SEO from "../SEO"
import "./index.scss"
import "./scss/typography.scss"


class index extends React.Component {
  render() {
    const { data } = this.props
    return (
      <>
        <GA />
        <Layout>
          <SEO
            title="Content Visit"
            description={data.contentfulSeo.homePageSeoDescription}
          />
          <Hero slogan={data.contentfulHomePageHeroSlogan.slogan} />
          <Clients />
          <MainServices />
          <Testimonials />
          <Blurb type="about" title="Who are we?" link="/about#about" />
          <Blurb type="process" title="Our Process" link="/work" />

          <LatestWork />
          {/* <LatestPosts /> */}

          {/* <Pricing seeMore={true} /> */}

          {/* <NewsLetter
            title="Newsletter."
            subtitle="Keep up to date with our news, work and services"
          /> */}
          <Contact />
        </Layout>

      </>
    )
  }
}

export const query = graphql`
  query HomeSlogan {
    contentfulSeo {
      homePageSeoDescription
    }
    contentfulHomePageHeroSlogan {
      slogan
    }
  }
`

export default index
